import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../style/main.scss';
import { headData } from '../mock/data';
import { Helmet } from 'react-helmet';
import { Link } from 'gatsby';
import { Container } from 'react-bootstrap';
import Title from '../components/Title/Title';

export default () => {
    const { title, lang, description } = headData;

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{title || 'Gatsby Simplefolio'}</title>
                <html lang={lang || 'en'} />
                <meta name="description" content={description || 'Gatsby Simplefolio'} />
            </Helmet>
            <section id="about" className="vh-100">
                <Container>
                    <Title title="Obrigado!" />
                    <p>Em breve entraremos em contato!</p>
                    <span className="cta-btn cta-btn--resume" style={{ cursor: "pointer" }}>
                        <Link to="/">Voltar</Link>
                    </span>
                </Container>
            </section>
        </>
    );
};
